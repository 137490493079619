import { LocalstorageStore } from '@cj4/store-localstorage'
import AyvensTheme from '@velocity/styling/themes/ayvens/theme'
import { GoogleMapsContextProvider, VelocityProvider } from '@velocity/ui'
import { useEffect, useRef, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { SWRConfig } from 'swr'

import { ContentProvider, getCountryL10nCtx } from '@ngb-frontend/content'
import {
  isValidSystemCountry,
  isValidSystemService,
} from '@ngb-frontend/features/flow-config'
import {
  AuthComponent,
  AuthProvider,
  useAppConfig,
} from '@ngb-frontend/shared/context'
import {
  ErrorVariants,
  SystemCode,
  type QueryParams,
} from '@ngb-frontend/shared/types'
import {
  ErrorBoundaryFallback,
  FullScreenLoader,
} from '@ngb-frontend/shared/ui'
import {
  useCleanup,
  getUserType,
  generateFetcher,
  generateCacheProvider,
  setupMSAL,
} from '@ngb-frontend/shared/utils'

import NoSSR from './NoSSR'
import ErrorPage from '../pages/error/[errorType].page'

import type { PageProps as ConfirmPageProps } from '../pages/confirm/[bookingId].page'
import type { PageProps as IndexPageProps } from '../pages/index.page'
import type { Store } from '@cj4/store'

type ChildPageProps = Partial<IndexPageProps | ConfirmPageProps>

type PageSessionProps = ChildPageProps & {
  component: React.ComponentType<ChildPageProps>
}

const invalidatingParams: (keyof QueryParams)[] = [
  'ilan',
  'service',
  'licensePlate',
  'caseId',
  'agentId',
  'systemCode',
  'country',
  'bookingId',
  'tenant',
]

type SessionConfig = {
  msalInstance: ReturnType<typeof setupMSAL> | undefined
  swr: Parameters<typeof SWRConfig>[0]['value']
  l10n: ReturnType<typeof getCountryL10nCtx>
}

const NoSSRLoader = () => {
  return (
    <NoSSR>
      <FullScreenLoader />
    </NoSSR>
  )
}

export const PageSession: React.FC<PageSessionProps> = ({
  component: Component,
  ...childPageProps
}) => {
  const config = useAppConfig()
  const [query, setQuery] = useState<QueryParams | null | undefined>(
    undefined, // null when we can't find from cache as well
  )

  const store = useRef<Store<QueryParams | null | undefined>>(
    new LocalstorageStore(config.localStorageKeys.queryParams),
  )
  const sessionConfig = useRef<SessionConfig>()
  const { clearLocalStorage, clearSWRCache } = useCleanup()

  // Before any routing, we need to ensure that url params were read. Update
  // always the store (source of truth) except the case that it is set already
  // and url does not have new parameters or they are malformed
  useEffect(() => {
    const storeQuery = store.current.get()
    //@ts-ignore
    const persistedQuery = childPageProps?.query || storeQuery || null

    const resetAllCaches = invalidatingParams.some(
      (p) => persistedQuery?.[p] !== storeQuery?.[p],
    )

    if (resetAllCaches) {
      clearLocalStorage({ exclude: ['swrCache'] })
      clearSWRCache()
    }

    store.current.set(persistedQuery)

    if (persistedQuery) {
      const msalInstance = !config.disableAuth
        ? setupMSAL(
            config.azure,
            persistedQuery.systemCode,
            persistedQuery.country,
            persistedQuery.tenant,
          )
        : undefined

      const swr = {
        fetcher: generateFetcher({
          msalInstance,
          systemCode: persistedQuery.systemCode,
        }),
        shouldRetryOnError: false,
        revalidateOnFocus: false,
        provider: generateCacheProvider(config.localStorageKeys.swrCache),
      }

      sessionConfig.current = {
        msalInstance,
        swr,
        l10n: getCountryL10nCtx(persistedQuery.country),
      }
    }

    store.current.set(persistedQuery)
    setQuery(persistedQuery)
  }, [
    //@ts-ignore
    childPageProps?.query,
    clearLocalStorage,
    clearSWRCache,
    config.azure,
    config.disableAuth,
    config.localStorageKeys.swrCache,
  ])

  if (query === undefined) return <FullScreenLoader />

  const ErrorVariant: ErrorVariants | undefined =
    query === null
      ? ErrorVariants.Query
      : !Object.values(SystemCode).includes(query.systemCode)
      ? ErrorVariants.SystemCode
      : !query.country
      ? ErrorVariants.Country
      : !isValidSystemCountry(query.systemCode, query.country)
      ? ErrorVariants.UnsupportedCountry
      : !isValidSystemService(getUserType(query.systemCode), query.service)
      ? ErrorVariants.Service
      : undefined

  const { l10n, msalInstance, swr } = sessionConfig.current || {}

  return (
    <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
      <VelocityProvider
        locale={l10n?.localeId}
        dateFnsLocales={l10n?.locale}
        theme={AyvensTheme}
      >
        <ContentProvider l10nCtx={l10n}>
          <AuthProvider
            disabled={config.disableAuth}
            msalApp={msalInstance?.client}
          >
            <AuthComponent
              systemCode={query?.systemCode || SystemCode.MYLP}
              loadingComponent={NoSSRLoader}
              disabled={config.disableAuth}
              azureRequest={msalInstance?.request}
            >
              <SWRConfig value={swr}>
                <GoogleMapsContextProvider apiKey={config.google.maps || ' '}>
                  <NoSSR>
                    {!ErrorVariant ? (
                      <Component {...childPageProps} />
                    ) : (
                      <ErrorPage type={ErrorVariant} />
                    )}
                  </NoSSR>
                </GoogleMapsContextProvider>
              </SWRConfig>
            </AuthComponent>
          </AuthProvider>
        </ContentProvider>
      </VelocityProvider>
    </ErrorBoundary>
  )
}
