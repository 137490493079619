import { Grid } from '@velocity/ui'
import * as R from 'ramda'
import React from 'react'

import { useContent } from '@ngb-frontend/content'
import { StepVariants } from '@ngb-frontend/shared/types'
import {
  CardButton,
  AddressCardButton,
  FullScreenLoader,
} from '@ngb-frontend/shared/ui'
import {
  useDriver,
  hasAddress,
  useQuery,
  useVehicle,
} from '@ngb-frontend/shared/utils'

import type {
  LocationStepPrerequisiteData,
  LocationStepValues,
  StepProps,
} from '@ngb-frontend/shared/types'

const LocationStep: React.FC<
  StepProps<LocationStepValues, LocationStepPrerequisiteData>
> = ({ onNextStep, onUpdateStep, stepVariant }) => {
  const query = useQuery()
  const { data: vehicle } = useVehicle({
    // Always pass an ilan and/or licensePlate to this hook call to enable app state overrides
    vehicleIdentifier: query?.vehicleIdentifier,
    country: query?.country,
  })
  const { data: driver } = useDriver(
    vehicle?.driverIdentifier || query?.licensePlate,
    query?.country,
  )

  const c = useContent()

  // Handle onUpdate step values here instead based on onClick evt instead of
  // recreating 3 callbacks on each rerender
  const handleSubmit = React.useCallback(
    (values: Partial<LocationStepValues>) => {
      onUpdateStep(values)
      onNextStep()
    },
    [onNextStep, onUpdateStep],
  )

  const addressHint =
    stepVariant === StepVariants.Location
      ? 'steps.location.searchAddress'
      : 'steps.locationWithPickup.specifyAddress'

  return driver ? (
    <Grid>
      <Grid.Item LG={4}>
        <AddressCardButton
          title={c('steps.location.home')}
          address={driver.home_address}
          onClick={() => handleSubmit({ location: driver.home_address })}
          data-e2e-component={`${stepVariant}-home`}
          data-dd-action-name="User home"
        />
        <AddressCardButton
          title={c('steps.location.work')}
          address={driver.work_address}
          onClick={() => handleSubmit({ location: driver.work_address })}
          data-e2e-component={`${stepVariant}-work`}
          data-dd-action-name="User work"
        />
        <CardButton
          title={c('steps.location.other')}
          data-e2e-component={`${stepVariant}-other`}
          // NB: Neglecting to set undefined breaks useFlow's routing since it
          // expects one stepData entry for any given step.
          onClick={() => handleSubmit({ location: undefined })}
        >
          {c(addressHint)}
        </CardButton>
      </Grid.Item>
    </Grid>
  ) : (
    <FullScreenLoader />
  )
}

export const hasLocationValues = (x: unknown): x is LocationStepValues =>
  R.allPass([R.pipe(R.prop('location'), hasAddress)])(x)

export default LocationStep
